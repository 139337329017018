(function ($) {
  $.fn.demoUpload = function () {
    var $uploadCrop;
    function readFile(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $('.upload-demo').addClass('ready');
          $uploadCrop.croppie('bind', {
            url: e.target.result
          }).then(function(){
            console.log('jQuery bind complete');
          });
        }
        reader.readAsDataURL(input.files[0]);
      }
      else {
        swal("Sorry - you're browser doesn't support the FileReader API");
      }
    }

    $uploadCrop = $('#upload-demo').croppie({
      enableExif: true,
      viewport: {
        width: 200,
        height: 200,
      },
      boundary: {
        width: 300,
        height: 300
      }
    });

    $('#upload').on('change', function () { readFile(this); });

    $('.upload-result').on('click', function (ev) {
      $uploadCrop.croppie('result', {
        type: 'canvas',
        size: 'viewport'
      }).then(function (resp) {
        console.log(resp);
        $('.photo').empty();
        $('.photo').append("<img src='" + resp + "'</img>");
        //$uploadCrop.toggle();
      });
    });
  }
}( jQuery ));
